import React, {useState} from 'react';
import Hash from '../../UI/Hash/Hash';
import Logo from '../Logo/Logo';
import IconsList from '../IconsList/IconsList';
import {bigIcons} from '../../assets/icons/assets';
import {Link} from 'react-router-dom';
import './Header.scss';

const Header = () => {
    const [isNavActive, setIsNavActive] = useState(false);

    const stylesForLinks = {
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'gap': '30px'
    }

    const burgerMenuToggle = (e) => {
        e.preventDefault()
        setIsNavActive(!isNavActive)
    }

    return (
        <header className={'header mb100'}>
            <section className={'section-header container'}>
                <Logo/>
                <nav onClick={() => setIsNavActive(false)} className={`nav ${isNavActive ? 'nav-active' : ''}`}>
                    <ul className={'nav-list'}>
                        <li className={'nav-item'}>
                            <Link to="/"><Hash/>home</Link>
                        </li>
                        <li className={'nav-item'}><a href='#'><Hash/>works</a></li>
                        <li className={'nav-item'}><Link to='/about'><Hash/>about-me</Link></li>
                        <li className={'nav-item'}><Link to='/contacts'><Hash/>contacts</Link></li>
                        <li className={'nav-item'}>EN</li>
                    </ul>
                    {isNavActive ? <IconsList styles={stylesForLinks} images={bigIcons}/> : ''}
                </nav>
                <a onClick={(e) => burgerMenuToggle(e)} className={`burger-btn ${isNavActive ? 'burger-btn-active' : ''}`}
                   href='#'><span></span></a>
            </section>
        </header>
    );
};

export default Header;