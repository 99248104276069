import React from 'react';
import Hash from "../../UI/Hash/Hash";
import ContactMe from "../ContactMe/ContactMe";
import './Contacts.scss';

const Contacts = () => {
    return (
        <section className={'section-contacts mb100'} data-aos={'fade-up'}>
            <h2 className={'section-title'}><Hash/>contacts</h2>
            <div className={'contacts'}>
                <div className={'contacts-desc'}>
                    <p>I'm looking for a work, interested in freelance opportunities.
                        However, if you have other request or question, don’t hesitate to contact me</p>
                </div>
                <ContactMe/>
            </div>
        </section>
    );
};

export default Contacts;