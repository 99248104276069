export const smallIcons = {
    github: require('./Github.png'),
    telegram: require('./Telegram.png'),
    vk: require('./vk.png')
};

export const bigIcons = {
    github: require('./bigGithub.png'),
    telegram: require('./bigTelegram.png'),
    vk: require('./bigVk.png')
}