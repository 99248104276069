import React from 'react';
import './SkillsItem.scss';

const SkillsItem = ({title, skills}) => {
    return (
        <div className={'skill-block'}>
            <div className={'skill-block-title'}>
                <h3>{title}</h3>
            </div>
            <div className={'skill-block-info'}>
                {skills.map(skill => {
                    return (
                        <span key={skill}>{skill}</span>
                    )
                })}
            </div>
        </div>
    );
};

export default SkillsItem;