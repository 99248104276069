export const facts = ['I like winter more than summer',
    'I like pizza and pasta',
    'I was in Germany and Poland',
    'I was a history teacher',
    'I go to gym',
    'I like to read books'];

export const skillsData = [
    {
        title: 'Languages',
        skills: ['Javascript']
    },
    {
        title: 'Other',
        skills: ['HTML', 'CSS', 'SCSS', 'TailWindCSS']
    },
    {
        title: 'Tools',
        skills: ['VSCode', 'WebStorm', 'Figma', 'Linux', 'Git']
    },
    {
        title: 'Frameworks',
        skills: ['React', 'Vue']
    }
];

export const study = [
    {
        title: 'Hexlet',
        img:require('../assets/images/hexlet.png'),
        text: 'I completed the entire course of the basic subscription for the Frontend developer profession.',
        button: {
            buttonText:'Go to my profile <~>',
            buttonLink:'https://ru.hexlet.io/u/vasili93'
        }
    },
    {
        title: 'Avenue',
        img:require('../assets/images/avenue.jfif'),
        text: 'For a year I studied the frontend developer course at an IT school.',
        button: {
            buttonText: 'View the certificate <~>',
            buttonLink: 'https://msk.avenue.school/certificates/21062022-499/'
        }
    },
    {
        title: 'Free Youtube courses',
        img:require('../assets/images/youtube.png'),
        text: 'I constantly watch various courses on YouTube. Some of my favorite channels are Ulbi TV, Vladilen Minin, Red Group, Glo Academy, IT-Camasutra.',
    },
    {
        title: 'Codewars',
        img:require('../assets/images/codewars.webp'),
        text: 'I like to solve various katas on Codewars.',
        button: {
            buttonText: 'Go to my profile <~>',
            buttonLink: 'https://www.codewars.com/users/Vasilkrug'
        }
    },
    {
        title: 'Books',
        img:require('../assets/images/books.png'),
        text: 'I also like to read various books about programming.Some of my favorites are Petzold, Charles - Code: The Hidden Language of Computer Hardware and Software,Aditya Y. Bhargava- Grokking Algorithms,David Flanagan - A detailed guide to Javascript',
    }
]
