import React from 'react';
import './Quote.scss';

const Quote = ({classname}) => {
    return (
        <div className={`quote-container ${classname}`} data-aos={'fade-up'}>
            <div className={'quote-text'}>
                <h3>With great power comes great electricity bill</h3>
            </div>
            <div className={'quote-author'}>
                <h3>- Dr. Who</h3>
            </div>
        </div>
    );
};

export default Quote;