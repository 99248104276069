import React from 'react';
import Hash from '../../UI/Hash/Hash';
import ContactForm from '../../components/ContactForm/ContactForm';
import './ContactsPage.scss';

const ContactsPage = () => {
    return (
        <section className={'contactsPage-section'} data-aos={'fade-up'}>
            <h2><Hash/>contacts</h2>
            <p>You can contact me on any social network or email me using the form below.</p>
           <div className={'my-contacts mb100'}>
               <ContactForm/>
           </div>
        </section>
    );
};

export default ContactsPage;