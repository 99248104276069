import React from 'react';
import './Button.scss';

const Button = ({text, link}) => {
    return (
        <button className={'my-button'}>
            <a href={link}>{text}</a>
        </button>
    );
};

export default Button;