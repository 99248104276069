import React from 'react';
import './Logo.scss';

const Logo = () => {
    return (
        <div className={'logo'}>
            <img src={require('../../assets/icons/logo.png')} alt='logo'/>
            <span className={'logo-name'}>Vasili</span>
        </div>
    );
};

export default Logo;