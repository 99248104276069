import React from 'react';
import './FactItem.scss';

const FactItem = ({fact}) => {
    return (
        <li className={'my-fact-item'}>
            <p>{fact}</p>
        </li>
    );
};

export default FactItem;