import React from 'react';
import Logo from '../Logo/Logo';
import IconsList from '../IconsList/IconsList';
import {smallIcons} from '../../assets/icons/assets';
import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <div className={'section-footer container'}>
                <div className={'section-footer-info'}>
                    <div className={'section-footer-desc'}>
                        <div className={'section-footer-desc-title'}>
                            <Logo/>
                            <a  href='mailto:vkruglovec@gmail.com'>vkruglovec@gmail.com</a>
                        </div>
                        <p>Beginner front-end developer based in Russia</p>
                    </div>
                    <div className={'footer-nav'}>
                        <h2>Media</h2>
                        <IconsList images={smallIcons}/>
                    </div>
                </div>
                <div className={'footer-policy'}>
                    <span>© Copyright 2023. Made by Vasili</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;