import React from 'react';
import Hash from '../../UI/Hash/Hash';
import SkillsLists from '../SkillsList/SkillsLists';
import './Skills.scss';

const Skills = ({styles, img}) => {

    return (
        <section className={'section-skills mb100'} data-aos={'fade-up'}>
            <h2 className={'section-title'}><Hash/>skills</h2>
            <div className={'skills'}>
                {img ? <img src={img} alt='image with elements'/> : ''}
                <SkillsLists styles={styles}/>
            </div>
        </section>
    );
};

export default Skills;