import React from 'react';
import {facts} from '../../helpers/data';
import FactItem from '../FactItem/FactItem';
import './FactsList.scss';

const FactsList = () => {
    return (
        <ul className={'my-facts-list'}>
            {facts.map((fact,index) => {
                return <FactItem fact={fact} key={index}/>
            })}
        </ul>
    );
};

export default FactsList;