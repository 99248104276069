import React from 'react';
import Profile from '../../components/Profile/Profile';
import Quote from '../../components/Quote/Quote';
import Skills from '../../components/Skills/Skills';
import Contacts from '../../components/Contacts/Contacts';
import img from '../../assets/images/elements.png';
import './HomePage.scss';

const HomePage = () => {
    const styles = {
        'display': 'flex',
        'flexWrap': 'wrap',
        'gap': '20px',
        'justifyContent': 'center',
    }


    return (
        <div className={'home-page'}>
            <Profile/>
            <Quote classname={'mb100'}/>
            <Skills styles={styles} img={img}/>
            <Contacts/>
        </div>
    );
};

export default HomePage;