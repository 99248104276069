import React, {useEffect} from "react";
import Header from './components/Header/Header';
import {Route, Routes, useLocation} from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import AboutMePage from './pages/AboutMePage/AboutMePage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './App.scss';

function App() {
    const {pathname} = useLocation();

    useEffect(()=> {
        window.scrollTo(0,0)
    },[pathname])

    useEffect(() => {
        AOS.init({
            duration:1000,
            once:true,
            offset:120,
        });
    },[])

    return (
            <div className={"App"}>
                <Header/>
                <main className={'content container'}>
                    <Routes>
                        <Route path={'/'} element={<HomePage/>}/>
                        <Route path={'/about'} element={<AboutMePage/>}/>
                        <Route path={'/contacts'} element={<ContactsPage/>}/>
                    </Routes>
                </main>
                <Footer/>
            </div>
    );
}

export default App;
