import React from 'react';
import Hash from '../../UI/Hash/Hash';
import './AboutMe.scss';

const AboutMe = () => {
    return (
        <section className={'section-about-me mb100'} data-aos={'fade-up'}>
            <div className={'about-me-title'}>
                <h2><Hash/>About me</h2>
                <br/>
                <p>Who i am?</p>
            </div>
            <div className={'about-me-desc'}>
                <div className={'about-me-text'}>
                    <p>Hello, i’m Vasili!</p>
                    <br/>
                    <p>I’m a self-taught front-end developer based in Moscow, Russia.
                        I can develop responsive websites from scratch and raise them into modern user-friendly web
                        experiences. </p>
                    <br/>
                    <p>Transforming my creativity and knowledge into a websites has been my passion for over a year.
                        I always strive to learn about the newest technologies and frameworks.</p>
                    <br/>
                </div>
                <div className={'about-me-img'}>
                    <img src={require('../../assets/images/about-me.png')} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default AboutMe;