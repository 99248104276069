import React from 'react';
import FactsList from '../FactsList/FactsList';
import Hash from '../../UI/Hash/Hash';
import './MyFacts.scss';

const MyFacts = () => {
    return (
        <section className={'section-my-facts mb100'} data-aos={'fade-up'}>
            <h2 className={'section-title'}><Hash/>my facts</h2>
            <div className={'my-facts-box'}>
                <FactsList/>
                <img src={require('../../assets/images/elements.png')} alt={'image with elements'}/>
            </div>
        </section>
    );
};

export default MyFacts;