import React from 'react';
import './ContactMe.scss';

const ContactMe = () => {
    return (
        <div className={'contact-me'}>
            <h3>Message me here</h3>
            <a href='mailto:vkruglovec@gmail.com'>
                <img
                    src={require('../../assets/icons/Email.png')}
                    alt='email icon'/>
                vkruglovec@gmail.com
            </a>
            <a href='https://t.me/vasilkrug'>
                <img src={require('../../assets/icons/Telegram.png')} alt='telegram icon'/>
                @Vasilkrug
            </a>
        </div>
    );
};

export default ContactMe;