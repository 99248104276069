import React from 'react';
import './IconsList.scss';

const IconsList = ({images,styles}) => {
    return (
        <nav className={'iconsListNav'} style={{...styles}}>
            <a href='https://github.com/Vasilkrug'>
                <img src={images.github} alt='link to github'/>
            </a>
            <a href="https://t.me/vasilkrug">
                <img src={images.telegram} alt='link to telegram'/>
            </a>
            <a href='https://vk.com/id44199330'>
                <img src={images.vk} alt="link to vk"/>
            </a>
        </nav>
    );
};

export default IconsList;