import React from 'react';
import SkillsItem from '../SkillsItem/SkillsItem';
import {skillsData} from '../../helpers/data';
import './SkillsList.scss';

const SkillsLists = ({styles}) => {

    return (
        <div className={'skills-list'} style={{...styles}}>
            {skillsData.map(skill => {
                return (
                    <SkillsItem key={skill.title} title={skill.title} skills={skill.skills}/>
                )
            })}
        </div>
    );
};

export default SkillsLists;