import React from 'react';
import Button from '../../UI/Button/Button';
import './StudyItem.scss';

const StudyItem = ({img, title, button, text}) => {
    return (
        <li className={'study-item'}>
            <img src={img} alt={title}/>
            <h3>{title}</h3>
            <p>{text}</p>
            {button ? <Button text={button.buttonText} link={button.buttonLink}/> : null}
        </li>
    );
};

export default StudyItem;