import React from 'react';
import AboutMe from '../../components/AboutMe/AboutMe';
import Skills from '../../components/Skills/Skills';
import MyFacts from '../../components/MyFacts/MyFacts';
import './AboutMePage.scss';
import StudyList from "../../components/StudyList/StudyList";

const AboutMePage = () => {
    const styles = {
        'display': 'flex',
        'flexWrap': 'wrap',
        'justifyContent': 'center',
        'gap': '30px',
    }
    return (
        <>
            <AboutMe/>
            <Skills styles={styles}/>
            <StudyList/>
            <MyFacts/>
        </>
    );
};

export default AboutMePage;