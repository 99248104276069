import React from 'react';
import Button from '../../UI/Button/Button';
import './ContactForm.scss';

const ContactForm = () => {
    return (
        <form className={'contacts-form'}>
            <div className={'form-user-info'}>
                <label htmlFor='name'>
                    <input type="text" placeholder='Name' id='name'/>
                </label>
                <label htmlFor='email'>
                    <input type="text" placeholder='Email' id='email'/>
                </label>

            </div>
            <div className={'form-title'}>
                <label htmlFor='title'>
                    <input type="text" placeholder='Title' id='title'/>
                </label>

            </div>
            <div className={'form-message'}>
                <label htmlFor='message'>
                    <textarea placeholder='Message' id='message'/>
                </label>

            </div>
            <div className={'form-button'}>
                <Button text={'Send <~>'}/>
            </div>
        </form>
    );
};

export default ContactForm;