import React from 'react';
import Hash from '../../UI/Hash/Hash';
import StudyItem from '../StudyItem/StudyItem';
import {study} from '../../helpers/data';
import './StudyList.scss';

const StudyList = () => {
    return (
        <section className={'section-study-list mb100'} data-aos={'fade-up'}>
            <h2 className={'section-title'}><Hash/>study</h2>
            <ul>
                {study.map(item => {
                    return <StudyItem key={item.title} img={item.img} button={item.button} title={item.title}
                                      text={item.text}/>
                })}

            </ul>
        </section>
    );
};

export default StudyList;