import React from 'react';
import Button from '../../UI/Button/Button';
import './Profile.scss';

const Profile = () => {
    return (
        <section className={'section-profile mb100'} data-aos={'fade-up'}>
            <div className={'profile-desc'}>
                <h1>I'm a beginner <span>front-end developer</span></h1>
                <p>I'm craft responsive websites where technologies meet creativity</p>
                <Button text={'Contact me!!'}/>
            </div>
            <div className={'profile-img'}>
                <img src={require('../../assets/images/profile.png')} alt='profile'/>
                <div className={'portfolio-status'}>
                    <span></span>
                    <p>Currently working on Portfolio</p>
                </div>
            </div>
        </section>
    );
};

export default Profile;